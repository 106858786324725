/* eslint-disable react/no-unescaped-entities */
"use client";
import ContactAlert from "@/app/_components/alerts/ContactAlert";
import {
  sendFormEvent,
  sendLeadGenerationEvent,
} from "@/app/_ga4/eventHandler";
import React, { useEffect, useState } from "react";

const RequestADemoForm = () => {
  const [clientIp, setClientIp] = useState("");
  let initState = {
    companyName: "",
    street: "",
    suite: "",
    companyName: "",
    street: "",
    suite: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    website: "",
    fullName: "",
    jobPosition: "",
    officeNumber: "",
    mobileNumber: "",
    email: "",
    numberOfLocations: "",
    comments: "",
    origin: "TL_Website",
    ip: clientIp,
  };
  const [formData, setFormData] = useState(initState);
  const [errors, setErrors] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [alertStatus, setAlertStatus] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [formStarted, setFormStarted] = useState(false);

  const handleChange = (e) => {
    if (!formStarted) {
      sendFormEvent("DEMO_REQUEST", "START");
      setFormStarted(true);
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    // Validate each field individually
    if (!formData.companyName.trim()) {
      errors.companyName = "Company Name is required";
      isValid = false;
    }

    if (!formData.street.trim()) {
      errors.street = "Street Address is required";
      isValid = false;
    }

    if (!formData.city.trim()) {
      errors.city = "City is required";
      isValid = false;
    }

    if (!formData.state.trim()) {
      errors.state = "State is required";
      isValid = false;
    }

    if (!formData.zip.trim()) {
      errors.zip = "Zip Code is required";
      isValid = false;
    }

    if (!formData.country.trim()) {
      errors.country = "Country is required";
      isValid = false;
    }

    if (!formData.fullName.trim()) {
      errors.fullName = "Full Name is required";
      isValid = false;
    }

    if (!formData.jobPosition.trim()) {
      errors.jobPosition = "Job Position is required";
      isValid = false;
    }

    if (!formData.officeNumber.trim()) {
      errors.officeNumber = "Office Number is required";
      isValid = false;
    }

    if (!formData.mobileNumber.trim()) {
      errors.mobileNumber = "Mobile Number is required";
      isValid = false;
    }

    if (!formData.email.trim()) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
      isValid = false;
    }

    if (!formData.numberOfLocations.trim()) {
      errors.numberOfLocations = "Number of Locations is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };
  const closeAlert = () => {
    setShowAlert(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      sendFormEvent("DEMO_REQUEST", "SUBMIT");
      let response = await fetch("/api/contact", {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(formData),
      });
      response = await response.json();
      if (response.success) {
        setAlertStatus("Success");
        setShowAlert(true);
        setAlertMessage(response.message);
        setFormData(initState);
        setFormStarted(false);
        sendLeadGenerationEvent("DEMO_REQUEST");
        return response;
      } else {
        setAlertStatus("Failed");
        setShowAlert(true);
        setAlertMessage(response.message);
        sendFormEvent("DEMO_REQUEST", "INVALID/ERROR");
        return response;
      }
    }
  };

  useEffect(() => {
    async function fetchClientIp() {
      try {
        const response = await fetch("https://api64.ipify.org?format=json");
        const data = await response.json();
        setClientIp(data.ip);
      } catch (error) {
        console.error("Error fetching client IP:", error);
      }
    }
    fetchClientIp();
  });

  return (
    <>
      {showAlert && (
        <ContactAlert
          showAlert={showAlert}
          alertMessage={alertMessage}
          alertStatus={alertStatus}
          registeredSerial={null}
          closeAlert={closeAlert}
        />
      )}
      <section className="demo-section">
        <div className="left">
          <h1>Request a Demo</h1>
          <p>
            Fill out the form to request a demo of our products. A member of our
            team will be in touch with you shortly to schedule a demo.
          </p>
        </div>
        <div className="right">
          <form onSubmit={handleSubmit} className="demo-form">
            <div className="form-heading">Company Information</div>
            <div className="input-group">
              <label htmlFor="companyName">
                Company Name:{" "}
                {errors.companyName && (
                  <span className="error">{errors.companyName}</span>
                )}
              </label>
              <input
                type="text"
                id="companyName"
                name="companyName"
                placeholder="Enter company name"
                value={formData.companyName}
                onChange={handleChange}
              />
            </div>
            <div className="two-column-form-group">
              <div className="input-group">
                <label htmlFor="street">
                  Street Address:{" "}
                  {errors.street && (
                    <span className="error">{errors.street}</span>
                  )}
                </label>
                <input
                  type="text"
                  id="street"
                  name="street"
                  placeholder="Enter street address"
                  value={formData.street}
                  onChange={handleChange}
                />
              </div>
              <div className="input-group">
                <label htmlFor="suite">Suite:</label>
                <input
                  type="text"
                  id="suite"
                  name="suite"
                  placeholder="Enter suite"
                  value={formData.suite}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="three-column-form-group">
              <div className="input-group">
                <label htmlFor="city">
                  City:{" "}
                  {errors.city && <span className="error">{errors.city}</span>}
                </label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  placeholder="Enter city"
                  value={formData.city}
                  onChange={handleChange}
                />
              </div>
              <div className="input-group">
                <label htmlFor="state">
                  State:{" "}
                  {errors.state && (
                    <span className="error">{errors.state}</span>
                  )}
                </label>
                <input
                  type="text"
                  id="state"
                  name="state"
                  placeholder="Enter state"
                  value={formData.state}
                  onChange={handleChange}
                />
              </div>
              <div className="input-group">
                <label htmlFor="zip">
                  Zip:{" "}
                  {errors.zip && <span className="error">{errors.zip}</span>}
                </label>
                <input
                  type="text"
                  id="zip"
                  name="zip"
                  placeholder="Enter zip code"
                  value={formData.zip}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="two-column-form-group">
              <div className="input-group">
                <label htmlFor="country">
                  Country:{" "}
                  {errors.country && (
                    <span className="error">{errors.country}</span>
                  )}
                </label>
                <input
                  type="text"
                  id="country"
                  name="country"
                  placeholder="Enter country"
                  value={formData.country}
                  onChange={handleChange}
                />
              </div>
              <div className="input-group">
                <label htmlFor="website">Website:</label>
                <input
                  type="text"
                  id="website"
                  name="website"
                  placeholder="Enter website URL"
                  value={formData.website}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-heading">Primary Contact Info</div>
            <div className="two-column-form-group">
              <div className="input-group">
                <label htmlFor="fullName">
                  Full Name:{" "}
                  {errors.fullName && (
                    <span className="error">{errors.fullName}</span>
                  )}
                </label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  placeholder="Enter full name"
                  value={formData.fullName}
                  onChange={handleChange}
                />
              </div>
              <div className="input-group">
                <label htmlFor="jobPosition">
                  Job Position:{" "}
                  {errors.jobPosition && (
                    <span className="error">{errors.jobPosition}</span>
                  )}
                </label>
                <input
                  type="text"
                  id="jobPosition"
                  name="jobPosition"
                  placeholder="Enter job position"
                  value={formData.jobPosition}
                  onChange={handleChange}
                />
              </div>
              <div className="input-group">
                <label htmlFor="officeNumber">
                  Office Number:{" "}
                  {errors.officeNumber && (
                    <span className="error">{errors.officeNumber}</span>
                  )}
                </label>
                <input
                  type="tel"
                  id="officeNumber"
                  name="officeNumber"
                  placeholder="Enter office number"
                  value={formData.officeNumber}
                  onChange={handleChange}
                />
              </div>
              <div className="input-group">
                <label htmlFor="mobileNumber">
                  Mobile Number:{" "}
                  {errors.mobileNumber && (
                    <span className="error">{errors.mobileNumber}</span>
                  )}
                </label>
                <input
                  type="tel"
                  id="mobileNumber"
                  name="mobileNumber"
                  placeholder="Enter mobile number"
                  value={formData.mobileNumber}
                  onChange={handleChange}
                />
              </div>
              <div className="input-group">
                <label htmlFor="email">
                  Email:{" "}
                  {errors.email && (
                    <span className="error">{errors.email}</span>
                  )}
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="input-group">
                <label htmlFor="numberOfLocations">
                  Number of Locations:{" "}
                  {errors.numberOfLocations && (
                    <span className="error">{errors.numberOfLocations}</span>
                  )}
                </label>
                <input
                  type="number"
                  id="numberOfLocations"
                  name="numberOfLocations"
                  placeholder="Enter number of locations"
                  value={formData.numberOfLocations}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="input-group">
              <label htmlFor="comments">Comments:</label>
              <textarea
                id="comments"
                name="comments"
                placeholder="Enter comments"
                value={formData.comments}
                onChange={handleChange}
              ></textarea>
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
      </section>
    </>
  );
};

export default RequestADemoForm;
